export const projectList = [
    {
        title: 'BetEasy Frontend SPA',
        description: 'As a member of the ease-of-use mission team at BetEasy, ' +
        'I regularly contribute to uplifting our customer experience by implementing exciting new functionalities with the use of cutting-edge technologies, '+
        'porting over legacy pages that reside in a Laravel + Backbone environment to the more performant React SPA '+
        'and constantly improving the performance levels of our user interface.',
        skills: [
            'ReactJS',
            'ReduxJS',
            'Redux Saga',
            'React Context',
            'Axios',
            'CSS3',
            'CSS Modules',
            'Laravel',
            'PHP',
            'RESTful APIs',
            'AWS',
            'Jenkins',
            'ESLint',
            'Visual Studio Code',
        ],
        via: 'BetEasy Pvt Ltd.',
    },
    {
        title: 'Site Rebrand',
        description: 'I was a part of a larger frontend team that was formed to rebrand the BetEasy site.' +
        'In this project, our team decided to further uplift our tech stack and utilize TypeScript and emotionJS ' +
        'to conform with latest industry standards, increase maintainability and agility in our codebase.',
        skills: [
            'ReactJS',
            'Typescript',
            'ReduxJS',
            'Redux Saga',
            'React Context',
            'Axios',
            'CSS3',
            'emotionJS',
            'theme-ui',
            'Laravel',
            'PHP',
            'RESTful APIs',
            'AWS',
            'LAMP',
            'Jenkins',
            'ESLint',
            'Prettier',
            'Visual Studio Code',
        ],
        via: 'BetEasy Pvt Ltd.',
    },
    {
        title: 'AFL, NRL & NFL Swipe Games',
        description: 'AFL, NRL & NFL are custom built 3D games that are standalone ReactJS apps which use a WordPress site as their backend' +
        ' to store entries and configurations. They have been built this way so that regular promotion based configurations could be done' +
        ' on the WordPress backend by non developers as and when they are needed. I contributed to these projects by making ad-hoc modifications and feature implementations',
        skills: [
            'ReactJS',
            'ReduxJS',
            'Redux Saga',
            'AJAX',
            'CSS Modules',
            'PHP',
            'RESTful APIs',
            'AWS',
            'Jenkins',
            'ESLint',
            'Visual Studio Code',
        ],
        via: 'BetEasy Pvt Ltd.',
    },
    {
        title: 'BetEasy Minisites',
        description: 'BetEasy minisites include BetEasy Blog, Promotions and MillersGuide, which have required ad-hoc changes '+
        'and feature implementations that I have contributed to as a WordPress specialist',
        skills: [
            'WordPress',
            'CSS3',
            'SASS',
            'Roots.io (Sage)',
            'ReactJS',
            'ReduxJS',
            'Redux Saga',
            'AJAX',
            'PHP',
            'RESTful APIs',
            'Jenkins',
            'ESLint',
            'Visual Studio Code',
        ],
        via: 'BetEasy Pvt Ltd.',
    },
    {
        title: 'Project Asterisk',
        description: 'A personal project and a revolutionary advertising platform that\'s currently being worked on..',
        skills: [
            'ReactJS',
            'ReduxJS',
            'Redux Saga',
            'AJAX',
            'SASS',
            'CSS Modules',
            'Laravel',
            'PHP',
            'MySQL',
            'RESTful APIs',
            'AWS',
            'LAMP',
            'ESLint',
            'Visual Studio Code',
        ],
        via: 'Personal Project',
    },
    {
        title: 'Maintain Me',
        description: 'Maintain Me is a gardening services provider based in Sydney New South Wales and building their website was one of the first and most interesting WordPress projects that I was involved in, during my time at Weboptimizers Pty Ltd. Though it may seem quite simple, it includes a complex booking system that utilises Google\'s Calendar API and comprehensive user portals for all parties involved including the customers, workers and the admins, which are seamlessly integrated with the organisation\'s CRM system. ',
        skills: [
            'WordPress',
            'ReactJS',
            'PHP',
            'ACF + Custom Posts',
            'RESTful APIs',
            'AWS',
            'LAMP',
            'jQuery',
            'AJAX',
            'HTML',
            'CSS3',
            'Bootstrap',
        ],
        via: 'Weboptimizers Pty Ltd.',
    },
    {
        title: 'Lunapark Melbourne',
        description: 'Whilst I made some contributions in the WordPress department, most of my contributions to Lunapark Melbourne would fall into the server management/enhancement category. Provided that it is one of the busiest websites in Melbourne, my and team and I had to ensure that it was stable, dynamically allocated with adequate amount of resources and constantly monitored especially during peak traffic seasons of the year. This process involved periodical maintenance sessions and constant improvement of its cloud infrastructure.',
        skills: [
            'AWS',
            'Elastic Load Balancing',
            'Auto Scaling Groups',
            'LAMP',
            'CSF',
            'Cloudflare',
            'CloudFront',
            'SSH + SCP',
            'WordPress',
            'CSS3',
        ],
        via: 'Weboptimizers Pty Ltd.',
    },
    {
        title: 'Audio Trends',
        description: 'Audio Trends is one of the biggest specialised audio retailers in Australia and my work on their website entailed creating new features such as e gift cards, bundled products and automated shipping package slips. I also closely worked with maintaining and managing their cloud infrastructure.',
        skills: [
            'Magento',
            'PHP',
            'JavaScript',
            'AJAX',
            'HTML',
            'CSS3',
            'AWS',
            'CloudFront',
            'CSF',
            'LAMP',
        ],
        via: 'Weboptimizers Pty Ltd.',
    },
    {
        title: 'Stephanie Browne',
        description: 'Stephanie Browne Australia is a jewellery retailer based in Melbourne Victoria. Due to requiring more agility in managing and updating content and to enhance SEO, the original Stephanie Browne website was decided to be moved from Squarespace to  Shopify. My contributions to this project ranged from creating and implementing the new website design, importation of data, integrating with payment portals and finally making it SEO friendly as per instructions provided by our SEO specialists at the time.',
        skills: [
            'Shopify + Liquid',
            'HTML',
            'SASS',
            'JavaScript',
            'Photoshop',
        ],
        via: 'Weboptimizers Pty Ltd.',
    },
    {
        title: 'J A Quantity Surveyors',
        description: 'J A Quantity Surveyors is a surveying business based in Melbourne Victoria and they were in need of boosting their brand awareness and giving a more premium impression on their services, which their original site didn\'t quite manage to deliver. My contributions to this revamp project included designing and implementing the website, preparing the hosting environment, testing and improving overall site SEO levels.',
        skills: [
            'WordPress',
            'ACF + Custom Posts',
            'HTML',
            'CSS3',
            'jQuery',
            'SSH + SCP',
            'Photoshop',
        ],
        via: 'Freelance Project',
    },
];